export const FETCH_ADMIN_USERS = "FETCH_ADMIN_USERS";
export const FETCH_ADMIN_USERS_SUCCESS = "FETCH_ADMIN_USERS_SUCCESS";

export const ADD_ADMIN_USERS = "ADD_ADMIN_USERS";
export const ADD_ADMIN_USERS_SUCCESS = "ADD_ADMIN_USERS_SUCCESS";

export const UPDATE_ADMIN_USERS = "UPDATE_ADMIN_USERS";
export const UPDATE_ADMIN_USERS_SUCCESS = "UPDATE_ADMIN_USERS_SUCCESS";

export const DELETE_ADMIN_USERS = "DELETE_ADMIN_USERS";
export const DELETE_ADMIN_USERS_SUCCESS = "DELETE_ADMIN_USERS_SUCCESS";

export const FETCH_ADMIN_PAYOUT_TRAIL = "FETCH_ADMIN_PAYOUT_TRAIL";
export const FETCH_ADMIN_PAYOUT_TRAIL_SUCCESS = "FETCH_ADMIN_PAYOUT_TRAIL_SUCCESS";

export const FETCH_ADMIN_AUDIT_LOGS = "FETCH_ADMIN_AUDIT_LOGS";
export const FETCH_ADMIN_AUDIT_LOGS_SUCCESS = "FETCH_ADMIN_AUDIT_LOGS_SUCCESS";

export const fetchAdminUsersGenerator = (data=[])=>{
    return {
        type:FETCH_ADMIN_USERS,
        payload:data
    }
} ;
export const fetchAdminUsersSuccessGenerator = (data=[])=>{
    return {
        type:FETCH_ADMIN_USERS_SUCCESS,
        payload:data
    }
} ;
export const addAdminUsersGenerator = (data=[])=>{
    return {
        type:ADD_ADMIN_USERS,
        payload:data
    }
} ;
export const addAdminUsersSuccessGenerator = (data=[])=>{
    return {
        type:ADD_ADMIN_USERS_SUCCESS,
        payload:data
    }
} ;
export const updateAdminUsersGenerator = (data=[])=>{
    return {
        type:UPDATE_ADMIN_USERS,
        payload:data
    }
} ;
export const updateAdminUsersSuccessGenerator = (data=[])=>{
    return {
        type:UPDATE_ADMIN_USERS_SUCCESS,
        payload:data
    }
} ;
export const deleteAdminUsersGenerator = (data=[])=>{
    return {
        type:DELETE_ADMIN_USERS,
        payload:data
    }
} ;
export const deleteAdminUsersSuccessGenerator = (data=[])=>{
    return {
        type:FETCH_ADMIN_USERS_SUCCESS,
        payload:data
    }
} ;

export const fetchAdminPayoutTrailGenerator = (data={})=>{
    return {
        type:FETCH_ADMIN_PAYOUT_TRAIL,
        payload:data
    }
};
export const fetchAdminPayoutTrailSuccessGenerator = (data={})=>{
    return {
        type:FETCH_ADMIN_PAYOUT_TRAIL_SUCCESS,
        payload:data
    }
};

export const fetchAdminAuditTrailsGenerator = (data={})=>{
    return {
        type:FETCH_ADMIN_AUDIT_LOGS,
        payload:data
    }
};
export const fetchAdminAuditTrailsSuccessGenerator = (data={})=>{
    return {
        type:FETCH_ADMIN_AUDIT_LOGS_SUCCESS,
        payload:data
    }
};
