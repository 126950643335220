import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import IntlMessages from 'util/IntlMessages';
import {
  userSignInGenerator,
  confirmTokenGenerator,
  userSignOutSuccessGenerator
} from "../appRedux/actions/AuthActions";
import { Helpers } from '../util/Helpers';
import CircularProgress from "@material-ui/core/CircularProgress";
import { InputAdornment, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {BETA_API_ADDRESS, SIGNIN_USER} from "../constants/ActionTypes";
import { USER_EMAIL } from '../constants/SessionKeys';
import Redirect from "react-router/Redirect";
const queryString = require('query-string');

const useStyles = theme => ({
    buttonProgress: {
        color: '#fff',
        marginRight: 5,
    },
    eye: {
        cursor: 'pointer',
    },

});

class SignIn extends React.Component {
  constructor(props) {
    let queryParameters = queryString.parse(props.location.search);
    let utm_source = queryParameters.utm_source;
    let amount = queryParameters.amount;
    let phoneNumber = queryParameters.phoneNumber;
    let token = queryParameters.utm_content;
    let company_id = queryParameters.company_id;
    let target = queryParameters.utm_target;

    super(props);
    this.state = {
      email: '',
      password: '',
      buttonLoading:false,
      passwordIsMasked: true,
      utm_source: utm_source,
      amount: amount,
      phoneNumber: phoneNumber,
      token: token,
      target,
      company_id
    };
  }

  componentDidMount() {
    const { utm_source, token, amount, phoneNumber, company_id, target } = this.state;
    if(utm_source && token && token !== '' && this.props.authUser){//if user is already signed in, auto-sign  them out to allow for a log into the token account
        this.props.userSignOutSuccessGenerator({notify:false});
    }
    if(utm_source === 'onboarding'){
      this.props.confirmTokenGenerator({token: token, amount, phoneNumber });
    }else if(utm_source === 'beta'){
      this.props.confirmTokenGenerator({token: token, company_id, target });
    }
    this.prefillEmail();
  }

  prefillEmail() {
    const userEmail = Helpers.getItem(USER_EMAIL);
    if(userEmail) {
      this.setState({ email: userEmail });
    }
  }

    togglePasswordMask = () => {
        this.setState(prevState => ({
            passwordIsMasked: !prevState.passwordIsMasked,
        }));
    };

  render() {
    const {
      email,
      password,
      buttonLoading,
        passwordIsMasked,
    } = this.state;

    const {classes } =this.props;
    const {token, utm_source} = this.state;
    const userIsFromAnotherApp = token && utm_source && this.props.location.search;
    // if(!userIsFromAnotherApp && !window.location.href.includes('localhost') && !window.location.href.includes('127.0.0.1')){
    //   window.location.href = "https://dashboard.myworkpay.com";//this can be replaced by an env variable when staging checks are required// the /login was removed since beta may have an existing session
    //   return []
    // }

    const loginClicked = () => {
      this.props.userSignInGenerator({
        email,
        password
      });
      this.setState({buttonLoading:true});
    };
    const loading = Helpers.loading(this.props);
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="">
              <img src={require('assets/images/logo.png')} alt="" title="" style={{ height:"40px"}} />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-1 text-center text-muted">
              <p><IntlMessages id={userIsFromAnotherApp ? "appModule.autoSignInDesc" : "appModule.signInDesc"} /></p>
            </div>

            {
              userIsFromAnotherApp ? null : (
                <div className="app-login-form">
                  <fieldset>
                    <TextField
                      label="Email or Phone Number"
                      placeholder="Email or Phone Number"
                      fullWidth
                      onChange={event => this.setState({ email: event.target.value })}
                      defaultValue={email}
                      value={email}
                      margin="normal"
                      className="mt-3 mb-3"
                    />
                    <TextField
                      type={passwordIsMasked ? 'password' : 'text'}
                      label={<IntlMessages id="appModule.password" />}
                      placeholder="Password"
                      fullWidth
                      onChange={event => this.setState({ password: event.target.value })}
                      defaultValue={password}
                      margin="normal"
                      className="mt-0 mb-3"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.togglePasswordMask}
                            >
                              {this.state.passwordIsMasked ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="d-flex align-items-center justify-content-between">
                      <Button
                        onClick={loginClicked}
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={loading&&buttonLoading}>
                        {(loading&&buttonLoading)&&<CircularProgress size={24} className={classes.buttonProgress} />} <IntlMessages id="appModule.signIn" />
                      </Button>
                      <a href="https://dashboard.myworkpay.com/signup">
                        <IntlMessages id="signIn.signUp" />
                      </a>
                    </div>
                    <div className="mb-3 d-flex align-items-center justify-content-end">
                      <Link to="/reset-password">
                      </Link>
                      <Link to="/forgot-password">
                        <IntlMessages id="appModule.forgotPassword" />
                      </Link>
                    </div>
                  </fieldset>
                </div>
              )
            }

          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = ({auth, general}) => {
  const {authUser} = auth;
  let {appLoading} = general;
  const loaderTypes= [SIGNIN_USER];
  return {
    authUser,
    appLoading,
    loaderTypes
  };
};

export default withStyles(useStyles)(connect(mapStateToProps, {userSignInGenerator, confirmTokenGenerator, userSignOutSuccessGenerator})(SignIn));
