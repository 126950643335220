import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { userSignUpGenerator } from '../appRedux/actions/AuthActions';
import { Helpers } from '../util/Helpers';
import withRouter from "react-router/es/withRouter";
import { IconButton, InputAdornment, withStyles } from "@material-ui/core";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {BETA_API_ADDRESS, SIGNUP_USER} from "../constants/ActionTypes";
import CircularProgress from "@material-ui/core/CircularProgress";
import { showMessageGenerator } from "../appRedux/actions/GeneralActions";
const queryString = require('query-string');


const useStyles = theme => ({
    buttonProgress: {
        color: '#fff',
        marginRight: 5,
    },
    eye: {
        cursor: 'pointer',
    },

});

class SignUp extends React.Component {
    constructor(props) {
        let queryParameters = queryString.parse(props.location.search);
        let fullName = queryParameters.name;
        let email = queryParameters.email;
        let phone = queryParameters.phone;
        let password = queryParameters.password;
        let confirmPass = queryParameters.confirmpassword;
        let first_name = '';
        let surname = '';
        try{
            let names = fullName.split(" ");
            if(Array.isArray(names)){
                if(names.count() >0){
                    first_name = names[0];
                }
                if(names.count() >1){
                    surname = names[1];
                }
            }
        }catch (e) {}

        super(props);
        this.state = {
            first_name: first_name || '',
            surname: surname || '',
            email: email || '',
            mobile: phone||'',
            password: password||'',
            confirm_password:confirmPass|| '',
            hasError:false,
            errorMessage:'',
            passwordIsMasked: true,
        };
    }

    componentDidMount() {
        // window.location = "https://dashboard.myworkpay.com/signup";
    }


    handleSubmit = (e) => {
        e.preventDefault();
        const data ={...this.state};
        if (this.validateForm() && !this.state.hasError) {
            this.props.userSignUpGenerator(data);
        }else{
            let message = data.errorMessage;
            if (!this.validateForm()){
                message = "Password do not match";
            }
            this.props.showMessageGenerator(message);
        }
    };

    validateForm() {
        const {
            password,
            confirm_password
        } = this.state;
        return (password === confirm_password);
    }
    validatePassword=(event)=>{
        const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        const number = /\d/g;
        const validLength = /.{6}/.test(event.target.value);
        const hasCaps = /[A-Z]/.test(event.target.value);
        const hasSmall = /[a-z]/.test(event.target.value);
        let hasError =false;
        let errorMessage ="";
        if (!format.test(event.target.value)) {
            hasError = true;
            errorMessage = "Include a special character";
        }else if (!number.test(event.target.value)) {
            hasError = true;
            errorMessage = "Password must have at least one number";
        }else if (!hasCaps){
            hasError = true;
            errorMessage = "Password must have at least one capital letter";
        }else if (!hasSmall){
            hasError = true;
            errorMessage = "Password must have a small letter";
        }else if (!validLength){
            hasError = true;
            errorMessage = "Password must be at least 6 characters";
        }
        this.setState({password:event.target.value,hasError:hasError,errorMessage:errorMessage});
    };

    togglePasswordMask = () => {
        this.setState(prevState => ({
            passwordIsMasked: !prevState.passwordIsMasked,
        }));
    };

    render() {
        const {
            first_name,
            surname,
            email,
            mobile,
            password,
            confirm_password,
            hasError,
            errorMessage,
            passwordIsMasked
        } = this.state;
        const {classes } =this.props;
        const loading = Helpers.loading(this.props);
        return (
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                {/*<div className="app-login-main-content">*/}

                {/*  <div className="app-logo-content d-flex align-items-center justify-content-center">*/}
                {/*    <Link className="logo-lg" to="/" title="">*/}
                {/*      <img src={require('assets/images/logo.png')} alt="" title="" style={{ height:"40px"}} />*/}
                {/*    </Link>*/}
                {/*  </div>*/}

                {/*  <div className="app-login-content">*/}
                {/*    <div className="app-login-header mb-1 text-center text-muted">*/}
                {/*      <h3><IntlMessages id="appModule.createAccount" /></h3>*/}
                {/*      <p><IntlMessages id="appModule.createAccountDesc" /></p>*/}
                {/*    </div>*/}

                {/*    <div className="app-login-form">*/}
                {/*      <form onSubmit={this.handleSubmit}>*/}
                {/*        <div className="row mb-2">*/}
                {/*          <div className="col-xl-6 col-lg-6 col-md-6 col-12">*/}
                {/*            <TextField*/}
                {/*              required*/}
                {/*              type="text"*/}
                {/*              label="First name"*/}
                {/*              id="firstName"*/}
                {/*              onChange={event => this.setState({first_name: event.target.value})}*/}
                {/*              fullWidth*/}
                {/*              defaultValue={first_name}*/}
                {/*              margin="normal"*/}
                {/*              className="mt-0 mb-3"*/}
                {/*            />*/}
                {/*          </div>*/}
                {/*          <div className="col-xl-6 col-lg-6 col-md-6 col-12">*/}
                {/*            <TextField*/}
                {/*              required*/}
                {/*              type="text"*/}
                {/*              label="Last name"*/}
                {/*              id="lastName"*/}
                {/*              onChange={event => this.setState({surname: event.target.value})}*/}
                {/*              fullWidth*/}
                {/*              defaultValue={surname}*/}
                {/*              margin="normal"*/}
                {/*              className="mt-0 mb-3"*/}
                {/*            />*/}
                {/*          </div>*/}
                {/*          <div className="col-xl-6 col-lg-6 col-md-6 col-12">*/}
                {/*            <TextField*/}
                {/*              required*/}
                {/*              type="email"*/}
                {/*              label="Email Address"*/}
                {/*              id="email"*/}
                {/*              onChange={event => this.setState({email: event.target.value})}*/}
                {/*              fullWidth*/}
                {/*              defaultValue={email}*/}
                {/*              margin="normal"*/}
                {/*              className="mt-0 mb-3"*/}
                {/*            />*/}
                {/*          </div>*/}
                {/*          <div className="col-xl-6 col-lg-6 col-md-6 col-12">*/}
                {/*            <TextField*/}
                {/*              required*/}
                {/*              type="number"*/}
                {/*              label="Phone Number"*/}
                {/*              id="phoneNumber"*/}
                {/*              onChange={event => this.setState({mobile: event.target.value})}*/}
                {/*              fullWidth*/}
                {/*              defaultValue={mobile}*/}
                {/*              margin="normal"*/}
                {/*              className="mt-0 mb-3"*/}
                {/*            />*/}
                {/*          </div>*/}
                {/*          <div className="col-xl-6 col-lg-6 col-md-6 col-12">*/}
                {/*            <TextField*/}
                {/*              required*/}
                {/*              type={passwordIsMasked ? 'password' : 'text'}*/}
                {/*              label="Password"*/}
                {/*              id="password"*/}
                {/*              onChange={this.validatePassword}*/}
                {/*              error={hasError}*/}
                {/*              helperText={errorMessage}*/}
                {/*              fullWidth*/}
                {/*              defaultValue={password}*/}
                {/*              margin="normal"*/}
                {/*              className="mt-0 mb-3"*/}
                {/*              InputProps={{*/}
                {/*                endAdornment: (*/}
                {/*                    <InputAdornment position="end">*/}
                {/*                      <IconButton*/}
                {/*                          aria-label="toggle password visibility"*/}
                {/*                          onClick={this.togglePasswordMask}*/}
                {/*                      >*/}
                {/*                        {this.state.passwordIsMasked ? <VisibilityOff /> : <Visibility />}*/}
                {/*                      </IconButton>*/}
                {/*                    </InputAdornment>*/}
                {/*                ),*/}
                {/*              }}*/}
                {/*            />*/}
                {/*            <span>{}</span>*/}
                {/*          </div>*/}
                {/*          <div className="col-xl-6 col-lg-6 col-md-6 col-12">*/}
                {/*            <TextField*/}
                {/*              required*/}
                {/*              type={passwordIsMasked ? 'password' : 'text'}*/}
                {/*              label="Confirm Password"*/}
                {/*              id="password"*/}
                {/*              onChange={event => this.setState({confirm_password: event.target.value})}*/}
                {/*              error={confirm_password !== null && confirm_password !== '' && password != confirm_password}*/}
                {/*              helperText={(confirm_password !== null && confirm_password !== '' && password != confirm_password) ? 'Password do not match.' : null}*/}
                {/*              fullWidth*/}
                {/*              defaultValue={confirm_password}*/}
                {/*              margin="normal"*/}
                {/*              className="mt-0 mb-3"*/}
                {/*            />*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*        <div className=" d-flex align-items-center justify-content-between">*/}
                {/*          <Button variant="contained" size="small" type="submit" color="primary"  >*/}
                {/*            {loading && <CircularProgress size={24} className={classes.buttonProgress} />} Next*/}
                {/*          </Button>*/}
                {/*          <Link to="/signin">*/}
                {/*            <IntlMessages id="signUp.alreadyMember" />*/}
                {/*          </Link>*/}
                {/*        </div>*/}
                {/*        <div className="mb-3 d-flex align-items-center justify-content-end">*/}
                {/*          <a href="https://tozzaplus.workpay.co.ke/terms">*/}
                {/*            <IntlMessages id="signUp.terms" />*/}
                {/*          </a>*/}
                {/*        </div>*/}
                {/*      </form>*/}
                {/*    </div>*/}
                {/*  </div>*/}

                {/*</div>*/}
            </div>
        );
    }
}

const mapStateToProps = ({auth, general}) => {
    const {authUser} = auth;
    const {appLoading} = general;
    const loaderTypes= [SIGNUP_USER];
    return {
        authUser,
        appLoading,
        loaderTypes
    };
};

export default withStyles(useStyles)(connect(mapStateToProps, {
    userSignUpGenerator,showMessageGenerator
})(withRouter(SignUp)));
