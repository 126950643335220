import React from 'react';
import {TextField} from '@material-ui/core';
import moment from "moment";
import {DatePicker} from "material-ui-pickers";
import {connect} from "react-redux";
import {
    addCompanyLoanPaymentGenerator,
} from "../../../../appRedux/actions/admin/CompanyLoanPaymentActions";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from 'react-select';

const Options = [{label:'Ignore Excess Amount', value:'IGNORE'}, {label: "Add to Borrower's Wallet", value: 'WALLET'}];

class PayLoan extends React.Component{
    constructor(props) {
        super(props);
        this.state ={
            workpay_transaction_code: this.props.workpay_transaction_code||'',
            company_d: this.props.company_id||'',
            amount: this.props.balance||'',
            displayBalance: this.props.balance||'',
            loan_id: this.props.id||'',
            end_date: this.props.end_date||'',
            date:moment().format('YYYY-MM-DD'),
            description: this.props.description || '',
            option: this.props.option || 'IGNORE'
        };
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        let dataSubmitted = false;
        let data = {...this.state};
        if (prevProps.submitClicked){
            switch (prevProps.action) {
                case 'create':
                    // console.log(data);
                    if(data.option === 'IGNORE' && Number(data.amount) > Number(prevProps.balance)){
                        data.amount = prevProps.balance;
                    }
                    this.props.addCompanyLoanPaymentGenerator(data);
                    dataSubmitted = true;
                    prevProps.closeModal(dataSubmitted);
                    break;
                default:
                    return;
            }
        }
    }

    updateState = (e) => {
        const { id, name, value } = e.target;
        const newState = Object.assign({}, this.state);
        const key = id || name;
        newState[key] = value;
        this.setState({ ...newState });
    };

    updateState1 = (name, e) => {
        const newState = Object.assign({}, this.state);
        newState[name] = e.value;
        this.setState({ ...newState });
    };

    updateStartDateState = (date) => {
        let dateF = moment(date).format('YYYY-MM-DD');
        this.setState({ date: dateF });
    };

    render() {
        const { workpay_transaction_code, amount, displayBalance,end_date, description } = this.state;

        return(
          <div className="row">
              <div className="col-12" style={{marginTop: 20}}>
                  <h5>Loan code {workpay_transaction_code} has a balance of <span className="text-success">KES {displayBalance}</span> to be paid by {end_date}.</h5>
              </div>
              <div className="col-12">
                  <TextField
                    id="amount"
                    label="Amount to Pay"
                    value={amount}
                    placeholder="Amount to Pay"
                    fullWidth
                    onChange={this.updateState}
                  />
              </div>
              {
                  this.state.amount > displayBalance &&
                    [
                        <div className="col-12">
                            <div className="alert alert-danger mt-2">
                                You have entered an amount that exceeds the loan balance.
                            </div>
                        </div>,
                        <div className="col-12">
                            <FormControl fullWidth margin="normal">
                                <InputLabel htmlFor="option" className="select-label">
                                    Option
                                </InputLabel>
                                <Select
                                  onChange={this.updateState1.bind(this,'option')}
                                  name="option"
                                  id="option"
                                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                  menuPortalTarget={document.body}
                                  options={Options}
                                  isSearchable={true}
                                  theme={(theme) => ({
                                      ...theme,
                                      //borderRadius: 0,
                                      colors: {
                                          ...theme.colors,
                                          primary25: '#deffc7',
                                          primary: '#3CB534',
                                      },
                                  })}
                                />
                            </FormControl>
                        </div>
                    ]
              }
              <div className="col-12">
                  <DatePicker
                      value={this.state.date}
                      style={{ marginTop: 16 }}
                      fullWidth
                      label="Date Paid"
                      placeholder="Date Paid"
                      format="YYYY-MM-DD"
                      onChange={this.updateStartDateState}
                      autoOk={true}
                  />
              </div>
              <div className="col-12">
                  <TextField
                    id="description"
                    name="description"
                    label="Remark"
                    value={description}
                    placeholder="Remark"
                    fullWidth
                    margin="normal"
                    onChange={this.updateState}
                  />
              </div>
          </div>
        );
    }
}
const mapStateToProps =({})=>{
    return {};
};
export default connect(mapStateToProps,{addCompanyLoanPaymentGenerator})(PayLoan)
