import {all, call, fork, put,takeLatest} from "redux-saga/effects";
import axios from "axios";
import {API_ENDPOINT, BETA_API_ADDRESS} from "../../../constants/ActionTypes";
import { Helpers } from "../../../util/Helpers";
import { userSignOutSuccessGenerator } from "../../actions/AuthActions";
import { hideAppLoaderGenerator, showAppLoaderGenerator, showMessageGenerator } from "../../actions/GeneralActions";
import { push } from "connected-react-router";
import {
  CONFIRM_PAYMENT, CONFIRM_SUBSCRIPTION_PAYMENT,
  confirmPaymentSuccessGenerator, confirmSubscriptionPaymentSuccessGenerator,
  SEND_REQUEST, SEND_SUBSCRIPTION_REQUEST,
  sendRequestSuccessGenerator, sendSubscriptionTopUpRequestSuccessGenerator,
  CONFIRM_CARD_PAYMENT_TRANSACTION, confirmCardPaymentTransactionSuccessGenerator,
  requestConfirmCardPaymentTransactionGenerator, requestCreateCardPaymentTransactionRecordGenerator,
  createCardPaymentTransactionRecordSuccessGenerator, CREATE_CARD_PAYMENT_TRANSACTION_RECORD
} from "../../actions/payouts/RechargePayoutActions";
import { fetchCompaniesGenerator } from "../../actions/common/CompanyActions";
import { fetchSubscriptionGenerator } from "../../actions/common/SubscriptionActions";

const sendRechargeMpesaRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+"/home/mpesa/transaction/request",requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const sendSubscriptionRechargeMpesaRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+"/home/subscription-topup",requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const confirmRechargeRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/home/mpesa/validation',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const confirmSubscriptionRechargeRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+'/home/confirm-subscription-topup',requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const sendConfirmCardPaymentRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+"/home/card/validation",requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

const sendCreateCardPaymentTransactionRecordRequest = async (requestParameters)=>
  await axios.post(API_ENDPOINT+"/home/card/transaction/request",requestParameters)
    .then(response=>response)
    .catch(error=>error.response);

function* confirmRecharge({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(confirmRechargeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(confirmPaymentSuccessGenerator(response.data.data));
        yield put(push(response.data.data.url));
        yield put(fetchCompaniesGenerator());
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* confirmSubscriptionRecharge({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(confirmSubscriptionRechargeRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(confirmSubscriptionPaymentSuccessGenerator(response.data.data));
        yield put(fetchSubscriptionGenerator());
        if (response.data.data.hasOwnProperty('url')) {
          // yield put(push(response.data.data.url));
          window.open('https://dashboard.myworkpay.com/login?utm_source=legacy&utm_content='+Helpers.token()+'&company_id='+Helpers.authSwitchedCompanyID(), '_self');
        }
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* sendRechargeMpesa({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(sendRechargeMpesaRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(sendRequestSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* sendSubscriptionRechargeMpesa({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  try {
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
   // console.log(requestParameters)
    const response = yield call(sendSubscriptionRechargeMpesaRequest, requestParameters);
    //console.log(response.data)
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(sendSubscriptionTopUpRequestSuccessGenerator(response.data.data));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    }else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    }else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* sendConfirmCardPayment({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  yield put(requestConfirmCardPaymentTransactionGenerator({}));
  try{
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(sendConfirmCardPaymentRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(confirmCardPaymentTransactionSuccessGenerator({}));
        yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

function* sendCreateCardPaymentTransactionRecord({type, payload}) {
  yield put(showAppLoaderGenerator(type));
  yield put(requestCreateCardPaymentTransactionRecordGenerator({}));
  try{
    let requestParameters = {...payload, token:Helpers.token(), auth_company_id:Helpers.authSwitchedCompanyID()};
    const response = yield call(sendCreateCardPaymentTransactionRecordRequest, requestParameters);
    if (response.status === 200 || response.status === 201) {
      if (response.data.success) {
        yield put(createCardPaymentTransactionRecordSuccessGenerator(response.data.data));
        // yield put(showMessageGenerator(response.data.message,'success'));
      } else {
        yield put(showMessageGenerator(response.data.message,'error'));
      }
    } else if(response.status === 401){
      Helpers.clearAuthData();
      yield put(userSignOutSuccessGenerator());
    } else {
      yield put(showMessageGenerator(response.statusText,'error'));
    }
  } catch (error) {
    yield put(showMessageGenerator(error,'error'));
  }
  yield put(hideAppLoaderGenerator(type));
}

export function* sendSubscriptionRechargeMpesaDefault () {
  yield takeLatest(SEND_SUBSCRIPTION_REQUEST,sendSubscriptionRechargeMpesa);
}

export function* sendRechargeMpesaDefault () {
  yield takeLatest(SEND_REQUEST,sendRechargeMpesa);
}

export function* confirmRechargeDefault () {
  yield takeLatest(CONFIRM_PAYMENT,confirmRecharge);
}

export function* confirmSubscriptionRechargeDefault () {
  yield takeLatest(CONFIRM_SUBSCRIPTION_PAYMENT,confirmSubscriptionRecharge);
}

export function* sendConfirmCardPaymentDefault () {
  yield takeLatest(CONFIRM_CARD_PAYMENT_TRANSACTION, sendConfirmCardPayment);
}

export function* sendCreateCardPaymentTransactionRecordDefault () {
  yield takeLatest(CREATE_CARD_PAYMENT_TRANSACTION_RECORD, sendCreateCardPaymentTransactionRecord);
}

export default function* rootSaga() {
  yield all([
      fork(sendRechargeMpesaDefault),
      fork(confirmRechargeDefault),
      fork(sendSubscriptionRechargeMpesaDefault),
      fork(confirmSubscriptionRechargeDefault),
      fork(sendConfirmCardPaymentDefault),
      fork(sendCreateCardPaymentTransactionRecordDefault)
    ]);
}
