import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import {Button} from "@material-ui/core";
import {Helpers} from "../../util/Helpers";
class Admin extends Component{

  componentDidMount() {
    const {history} = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`;// get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
            menuLi[j].classList.remove('open');
          }
        }
        this.classList.toggle('open');
      };
    }

    const activeLi = document.querySelector(`a[href="${pathname}"]`);// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {

    const {history} = nextProps;
    const pathname = `${history.location.pathname}`;// get current path

    const activeLi = document.querySelector(`a[href="${pathname}"]`);// select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) {

    }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some((fn) => {
        if (typeof document.body[fn] === 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) {

    }

    return null;
  }

  render() {
    const conditions = ['paul@tozza','paul@afrisoft','paul@workpay','hello@work','jackson@tozza','jack@work','jackson@afri','junior@']; /*added junior for testing*/
    const email = Helpers.authUser().email;
    const canAccessMpesaSettings = conditions.some(em=>email.includes(em));
    return (
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="sidebar.admin"/>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/admin/dashboard">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.admin.dashboard" />
                {' '}
            </span>
            </NavLink>
          </li>
        <li className="menu collapse-box">
          <Button>
            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
            <span className="nav-text">
              <IntlMessages id="sidebar.companies" />
            </span>
          </Button>
          <ul className="sub-menu">
            <li>
              <NavLink className="prepend-icon" to="/admin/companies">
                <span className="nav-text"><IntlMessages id="sidebar.allCompanies" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/admin/companies-appraisal">
                <span className="nav-text"><IntlMessages id="sidebar.withAppraisal" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/admin/companies-sender-ids">
                <span className="nav-text"><IntlMessages id="sidebar.withSenderIds" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/admin/qr-code">
                <span className="nav-text"><IntlMessages id="sidebar.admin.QRCode" /></span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/admin/salary-advances">
                <span className="nav-text">
                  <IntlMessages id="sidebar.salaryAdvances" />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="prepend-icon" to="/admin/companies/onboarding">
                <span className="nav-text">
                  <IntlMessages id="sidebar.onboarding" />
                </span>
              </NavLink>
            </li>
          </ul>
        </li>
          <li className="menu no-arrow">
            <NavLink to="/admin/users">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.systemUsers" />
                {' '}
            </span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.Transactions" />
            </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/admin/transactions">
                  <span className="nav-text"><IntlMessages id="sidebar.allTransactions" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/daily/reconciliation">
                  <span className="nav-text"><IntlMessages id="sidebar.reconciliation" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/bank-payments">
                  <span className="nav-text"><IntlMessages id="sidebar.bankPayments" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/audit-trails">
                  <span className="nav-text"><IntlMessages id="sidebar.auditTrails" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/transaction-reports">
                  <span className="nav-text"><IntlMessages id="sidebar.reports" /></span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.payroll.payrollData.loans" />
            </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/admin/loans-dashboard">
                  <span className="nav-text"><IntlMessages id="sidebar.loansDashboard" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/companies-loan-rates">
                  <span className="nav-text"><IntlMessages id="sidebar.withSetLoanRates" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/loan-lenders">
                  <span className="nav-text"><IntlMessages id="sidebar.loanLenders" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/loan-requests">
                  <span className="nav-text"><IntlMessages id="sidebar.loanRequests" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/companies-loans">
                  <span className="nav-text"><IntlMessages id="sidebar.withLoans" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/customer-statement">
                  <span className="nav-text"><IntlMessages id="sidebar.customerStatement" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/companies-loans-repayment">
                  <span className="nav-text"><IntlMessages id="sidebar.loansRepayment" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/loan-reports">
                  <span className="nav-text"><IntlMessages id="sidebar.loanReports" /></span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/admin/administrators">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.administrators" />
                {' '}
            </span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.subscription" />
            </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/admin/subscription/active">
                  <span className="nav-text"><IntlMessages id="sidebar.subscription.active" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/subscription/inactive">
                  <span className="nav-text"><IntlMessages id="sidebar.subscription.inactive" /></span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/admin/accounts/pricing">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.pricing" />
            </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/admin/mobile-notifications">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.mobileNotifications" />
            </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/admin/audit-logs">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.auditLogs" />
            </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/admin/statutory-filling-requests">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.statutoryFillingRequests" />
                {' '}
            </span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button>
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
              <IntlMessages id="sidebar.settings" />
            </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/admin/payouts-charge-rates">
                  <span className="nav-text"><IntlMessages id="sidebar.admin.payoutsChargeRates" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/banks">
                  <span className="nav-text"><IntlMessages id="sidebar.banks" /></span>
                </NavLink>
              </li>
              {
                canAccessMpesaSettings &&
                <li>
                  <NavLink className="prepend-icon" to="/admin/accounts/mpesa-settings">
                    <span className="nav-text"><IntlMessages id="sidebar.admin.mpesa"/></span>
                  </NavLink>
                </li>
              }
              <li>
                <NavLink className="prepend-icon" to="/admin/system-settings">
                  <span className="nav-text"><IntlMessages id="sidebar.systemSettings" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/withholding-tax-rates">
                  <span className="nav-text"><IntlMessages id="sidebar.withholdingTax" /></span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/subscription-pricing">
                  <span className="nav-text"><IntlMessages id="sidebar.subscriptionPlanPricing" /></span>
                </NavLink> 
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/salary-advance-settings">
                  <span className="nav-text">
                    <IntlMessages id="sidebar.salaryAdvances.Settings" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/salary-advance-fees">
                  <span className="nav-text">
                    <IntlMessages id="sidebar.SalaryAdvanceFees" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/admin/salary-advance-company-settings">
                  <span className="nav-text"><IntlMessages id="sidebar.salaryAdvancesLoans.companySettings" /></span>
                </NavLink>
              </li>

              <li>
                <NavLink className="prepend-icon" to="/admin/salary-advance-global-settings">
                  <span className="nav-text"><IntlMessages id="sidebar.salaryAdvancesLoans.globalSettings" /></span>
                </NavLink>
              </li>
            </ul>
          </li>
      </ul>
    );
  }
}
export default withRouter(Admin);
