import {
  DISBURSED_SALARY_ADVANCE_SUCCESS,
  FETCH_FINANCED_SALARY_ADVANCES_SUCCESS,
  FETCH_SALARY_ADVANCE_LOANS_SUCCESS
} from "../../actions/admin/CompaniesFinancedSalaryAdvanceActions";

const INIT_STATE = {
  FinancedSalaryAdvances: {},
  DisbursedSalaryAdvances: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_FINANCED_SALARY_ADVANCES_SUCCESS:
    case FETCH_SALARY_ADVANCE_LOANS_SUCCESS:
      return {
        ...state,
        FinancedSalaryAdvances: action.payload
      }
    case DISBURSED_SALARY_ADVANCE_SUCCESS:
      return {
        ...state,
        DisbursedSalaryAdvances: action.payload
      }
    default:
      return state;
  }
}
